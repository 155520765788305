import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MaterialModule } from '../material.module';
//import { DatePickerFormatDirective } from './date-picker-format.directive';
import { RouterModule } from '@angular/router';
import { MatSnackBarComponent } from './components/mat-snack-bar/mat-snack-bar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppLoaderComponent } from './components/app-loader/app-loader.component';
import { SeparatorDirective } from './separator.directive';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { HelpdekSupportComponent } from './components/helpdek-support/helpdek-support.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AngularSvgIconPreloaderModule } from 'angular-svg-icon-preloader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RateDialogComponent } from './components/rate-dialog/rate-dialog.component';
import { CommoditySellersComponent } from './components/commodity-sellers/commodity-sellers.component';
import { CertificatesComponent } from './components/certificates/certificates.component';
import { TradeInformationComponent } from './components/trade-information/trade-information.component';
import { UpdatePaymentsComponent } from './components/update-payments/update-payments.component';
import { ApifieldsComponent } from './components/apifields/apifields.component';
import { SftpfieldsComponent } from './components/sftpfields/sftpfields.component';
import { TradeInfoAbleaceComponent } from './components/trade-info-ableace/trade-info-ableace.component';

@NgModule({
  declarations: [   
    ConfirmationDialogComponent,
    MatSnackBarComponent,
    AppLoaderComponent,
    SeparatorDirective,
    ConfirmDialogComponent,
    HelpdekSupportComponent,
    RateDialogComponent, 
    CommoditySellersComponent,
    CertificatesComponent,
    TradeInformationComponent,
    UpdatePaymentsComponent,
    ApifieldsComponent,
    SftpfieldsComponent,
    TradeInfoAbleaceComponent
  ],
  exports:[
    AppLoaderComponent,
    SeparatorDirective,
    HelpdekSupportComponent,
    TradeInformationComponent,
    CommoditySellersComponent,
    CertificatesComponent,
    ApifieldsComponent,
    SftpfieldsComponent
  ],
  imports: [
    CommonModule,
    MatMomentDateModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    MatSnackBarModule,
    AngularSvgIconModule.forRoot(),
    AngularSvgIconPreloaderModule.forRoot({
        configUrl: '../../assets/icons.json'
      }),
  ],
  providers: [MatSnackBarComponent], 
    schemas:[
    CUSTOM_ELEMENTS_SCHEMA
],
})
export class SharedModule { }