import { Injectable } from '@angular/core';
import { Trade } from '../_models/trade';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { shareReplay } from 'rxjs/operators';

const baseUrl = `${environment.apiUrl}/orders`;
@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  registerOrder(payload:any){
    return this.http.post(`${baseUrl}/register-order`,payload)
  }
  reOrder(payload:any){
    console.log(payload);
    return this.http.put(`${baseUrl}/update-order?tradeID=${payload['tradeID']}`, payload)
  }

  updateOrder(tradeId:any,payload:any){
    return this.http.put(`${baseUrl}/update-order?tradeID=${tradeId}`,payload);
  }

  confirmOrder(tradeId:any){
    return this.http.put(`${baseUrl}/confirm-order?tradeID=${tradeId}`,{});
  }
  confirmOrderAble(tradeId:any){
    return this.http.put(`${baseUrl}/confirm-orderableace?tradeID=${tradeId}`,{});
  }

  getOrderDetails(tradeID:any){
    return this.http.get<any[]>(`${baseUrl}/get-orders-details?tradeID=${tradeID}`);
  }

  getOrderStatus(tradeID:any){
    return this.http.put<any[]>(`${baseUrl}/get-order-status?tradeID=${tradeID}`,{}).pipe(shareReplay());
  }
  getOrderStatusableace(tradeID:any){
    return this.http.put<any[]>(`${baseUrl}/get-order-statusableace?tradeID=${tradeID}`,{}).pipe(shareReplay());
  }

  uploadPurchaseConfirmation(tradeId:any, file:any){
    const formData = new FormData();
    formData.append('file', file);  
    return this.http.put(`${baseUrl}/upload-purchaseconfirmation?tradeID=${tradeId}`, formData);
  }

  uploadSaleConfirmation(tradeId:any, file:any){
    const formData = new FormData();
    formData.append('file', file);  
    return this.http.put(`${baseUrl}/upload-saleconfirmation?tradeID=${tradeId}`, formData);
  }

  uploadOfferLetter(tradeId:any, file:any){
    const formData = new FormData();
    formData.append('file', file);  
    return this.http.put(`${baseUrl}/upload-offetletter?tradeID=${tradeId}`, formData);
  }

  uploadSaleInstruction(tradeId:any, file:any){
    const formData = new FormData();
    formData.append('file', file);  
    return this.http.put(`${baseUrl}/upload-saleinstruction?tradeID=${tradeId}`, formData);
  }



}
