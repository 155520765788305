<div class="app-container" [ngClass]="{ 'bg-light': account }">

<!-- global alert -->
<alert></alert>
<div class="container-fluid" *ngIf="account" >
    <div class="row">
        <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse" *ngIf="account">
            <div class="position-sticky">
                <a class="navbar-brand me-0" href="#"><img src="../assets/images/sidelogo.png"></a>
                <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-white">
                    <span>General</span>
                    <a class="link-secondary" href="#" aria-label="Add a new report">
                    <span data-feather="plus-circle"></span>
                    </a>
                </h6>
            <!-- admin panel -->
            <ul class="nav flex-column general_menu" *ngIf="account.role === Role.Admin" >
                <li class="nav-item">
                <a class="nav-link" aria-current="page" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <!-- <i class="fa fa-home"></i> -->
                    <svg-icon name="home" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                    Home
                </a>
                </li>
                <li class="nav-item">
                <a class="nav-link" routerLink="admin/accounts" routerLinkActive="active">
                    <!-- <i class="fa fa-users" aria-hidden="true"></i> -->
                    <svg-icon name="clientList" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                    Client's List
                </a>
                </li>
                <li class="nav-item">
                <a class="nav-link" routerLink="admin/brokers" routerLinkActive="active">
                    <!-- <i class="fa fa-users" aria-hidden="true"></i> -->
                    <svg-icon name="brokerList" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                    Broker's List
                </a>
                </li>
                <li class="nav-item">
                <a class="nav-link" routerLink="admin/tradeRequest" routerLinkActive="active">
                    <!-- <i class="fa fa-file-text-o" aria-hidden="true"></i> -->
                    <svg-icon name="tradeRequest" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                    Trade Requests
                </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="admin/bank" routerLinkActive="active">
                        <!-- <i class="fa fa-file-text-o" aria-hidden="true"></i> -->
                        <span class="material-icons-outlined" style="position: relative;
                        top: 6px;">
                            account_balance
                            </span>
                        Banks
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="admin/bankTrades" routerLinkActive="active">
                        <!-- <i class="fa fa-file-text-o" aria-hidden="true"></i> -->
                        <svg-icon name="tradeRequest" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Bank Trades
                    </a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="admin/forms" routerLinkActive="active">                      
                        <svg-icon name="forms" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Forms
                    </a>
                </li> -->
                <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="admin/tradeStatus" routerLinkActive="active">                       
                        <svg-icon name="tradeStatus" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Trade Status
                    </a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" routerLink="admin/tradeHistory" routerLinkActive="active">
                        <!-- <i class="fa fa-file-text-o" aria-hidden="true"></i> -->
                        <svg-icon name="tradeHistory" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Trade History
                    </a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="admin/clientsRequest" routerLinkActive="active">
                        <svg-icon name="clientRequest" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Clients Request
                    </a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" routerLink="admin/payments" routerLinkActive="active">
                        <svg-icon name="payments" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Payments
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="admin/addUser" routerLinkActive="active">
                        <!-- <i class="fa fa-file-text-o" aria-hidden="true"></i> -->
                        <svg-icon name="addUser" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Add User
                    </a>
                </li>
                <!-- <li class="nav-item">
                <a class="nav-link" href="#">
                    <span data-feather="layers"></span>
                    Integrations
                </a>
                </li> -->
            </ul>
            <ul class="nav flex-column general_menu" *ngIf="account.role === Role.AdminOrbit" >
                <li class="nav-item">
                <a class="nav-link" aria-current="page" routerLink="/orbitadmin/admin" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <!-- <i class="fa fa-home"></i> -->
                    <svg-icon name="home" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                    Home
                </a>
                </li>
                <li class="nav-item">
                <a class="nav-link" routerLink="orbitadmin/tradeRequest" routerLinkActive="active">
                    <!-- <i class="fa fa-file-text-o" aria-hidden="true"></i> -->
                    <svg-icon name="tradeRequest" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                    Orbit Trade
                </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="orbitadmin/bank" routerLinkActive="active">
                        <!-- <i class="fa fa-file-text-o" aria-hidden="true"></i> -->
                        <span class="material-icons-outlined" style="position: relative;
                        top: 6px;">
                            account_balance
                            </span>
                        Banks
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="orbitadmin/bankTrades" routerLinkActive="active">
                        <!-- <i class="fa fa-file-text-o" aria-hidden="true"></i> -->
                        <svg-icon name="tradeRequest" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Bank Trades
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="orbitadmin/tradeHistory" routerLinkActive="active">
                        <!-- <i class="fa fa-file-text-o" aria-hidden="true"></i> -->
                        <svg-icon name="tradeHistory" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Trade History
                    </a>
                </li>
            </ul>
            <!-- support page for admin -->
            <div *ngIf="account.role === Role.Admin">
                <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-white">
                    <span>Support</span>
                    <a class="link-secondary" href="#" aria-label="Add a new report">
                    <span data-feather="plus-circle"></span>
                    </a>
                </h6>
                <ul class="nav flex-column mb-2">
                    <!-- <li class="nav-item">
                        <a class="nav-link" aria-current="page" routerLink="/user/helpdesk" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">
                            <svg-icon name="clientList" svgClass="bg-red-400 h-20 w-20" class="newOrderIcon"></svg-icon>
                            Community
                        </a>
                    </li> -->
                    <li class="nav-item">
                        <a class="nav-link" aria-current="page" routerLink="/admin/helpDesk" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">
                            <svg-icon name="contact" svgClass="bg-red-400 h-20 w-20" class="newOrderIcon"></svg-icon>
                            Help & Support
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" aria-current="page" routerLink="/admin/helpDesk/tickets" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">
                            <svg-icon name="contact" svgClass="bg-red-400 h-20 w-20" class="newOrderIcon"></svg-icon>
                            Tickets
                        </a>
                    </li>
                </ul>
            </div>

            <!-- client panel -->
            <ul class="nav flex-column general_menu" *ngIf="account.role === Role.User">
                <li class="nav-item">
                    <a class="nav-link" aria-current="page" routerLink="/user" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <svg-icon name="home" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Home
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" aria-current="page" routerLink="/user/newOrder" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" >
                        <svg-icon name="newOrder" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        New Order
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" aria-current="page" routerLink="/user/murabahaforms" routerLinkActive="active" >
                        <svg-icon name="murabahaForms" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Murabaha Form
                    </a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" aria-current="page" routerLink="/user/tradingStatus" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <svg-icon name="clientTradeStatus" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Trading Status
                    </a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" aria-current="page" routerLink="/user/pastTrades" routerLinkActive="active">
                        <svg-icon name="pastTradeWhite" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Past Trade
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" aria-current="page" routerLink="/user/payments" routerLinkActive="active">
                        <svg-icon name="clientPaymentStatus" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Payments
                    </a>
                </li>
            </ul>
            
            <ul class="nav flex-column general_menu" *ngIf="account.role === Role.Orbit">
                <li class="nav-item">
                    <a class="nav-link" aria-current="page" routerLink="/orbit" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <svg-icon name="home" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Home
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" aria-current="page" routerLink="/orbit/clientlist/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" >
                        <svg-icon name="newOrder" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Client List
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" aria-current="page" routerLink="/orbit/add-new-client" routerLinkActive="active" >
                        <svg-icon name="murabahaForms" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Add New Client
                    </a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" aria-current="page" routerLink="/user/pastTrades" routerLinkActive="active">
                        <svg-icon name="pastTradeWhite" svgClass="bg-red-400 h-20 w-20"></svg-icon>
                        Past Trade
                    </a>
                </li> -->
            </ul>
            <!-- support page for client -->
            <div *ngIf="account.role === Role.User">
                <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-white">
                    <span>Support</span>
                    <a class="link-secondary" href="#" aria-label="Add a new report">
                    <span data-feather="plus-circle"></span>
                    </a>
                </h6>
                <ul class="nav flex-column mb-2">
                    <li class="nav-item">
                        <a class="nav-link" aria-current="page" routerLink="/user/helpdesk" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">
                            <svg-icon name="contact" svgClass="bg-red-400 h-20 w-20" class="newOrderIcon"></svg-icon>
                            Helpdesk
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" aria-current="page" routerLink="/user/helpdesk/tickets" routerLinkActive="active">
                            <svg-icon name="contact" svgClass="bg-red-400 h-20 w-20" class="newOrderIcon"></svg-icon>
                            Tickets
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" aria-current="page" routerLink="/user/faq" routerLinkActive="active" >
                            <svg-icon name="clientSabiqMessage" svgClass="bg-red-400 h-20 w-20" class="newOrderIcon"></svg-icon>
                            FAQs
                        </a>
                    </li>
                </ul>
            </div>
            <div *ngIf="account.role === Role.Orbit">
                <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-white">
                    <span>Support</span>
                    <a class="link-secondary" href="#" aria-label="Add a new report">
                    <span data-feather="plus-circle"></span>
                    </a>
                </h6>
                <ul class="nav flex-column mb-2">
                    <li class="nav-item">
                        <a class="nav-link" aria-current="page" routerLink="/orbit/helpdesk" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">
                            <svg-icon name="contact" svgClass="bg-red-400 h-20 w-20" class="newOrderIcon"></svg-icon>
                            Helpdesk
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" aria-current="page" routerLink="/orbit/helpdesk/tickets" routerLinkActive="active">
                            <svg-icon name="contact" svgClass="bg-red-400 h-20 w-20" class="newOrderIcon"></svg-icon>
                            Tickets
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" aria-current="page" routerLink="/orbit/faq" routerLinkActive="active" >
                            <svg-icon name="clientSabiqMessage" svgClass="bg-red-400 h-20 w-20" class="newOrderIcon"></svg-icon>
                            FAQs
                        </a>
                    </li>
                </ul>
            </div>
         
            </div>
        </nav>
        <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4 bg-image">
            <header class="navbar navbar-light sticky-top bg-light flex-md-nowrap p-0 white">
                <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <!-- <nav class="navbar navbar-expand navbar-light bg-light white" >
                    <div class="navbar-nav">
                        <input type="text" placeholder="Search" class="search"><span class="search_icon"><i class="fa fa-search" aria-hidden="true"></i></span>
                    </div>
                </nav> -->
                <div class="bell__notifiaction"><i class="fa fa-bell-o fa-2x" aria-hidden="true"></i></div>
                <div class="user_image_header">
                    <a role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img *ngIf="account?.profilePicture" src={{account?.profilePicture}} width="90px" />
                            <img *ngIf="!account?.profilePicture"src="../assets/images/logo.png" width="90px">
                        <span class="profile_title">{{account.companyname}} &nbsp;&nbsp;&nbsp;<i class="fa fa-chevron-right" aria-hidden="true"></i></span></a>
                <div class="popup dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <ul class="dropdown">
                        <li class="profile_pic">
                            <img *ngIf="account?.profilePicture" src={{account?.profilePicture}} width="50px" />
                            <img *ngIf="!account?.profilePicture"src="../assets/images/logo.png" width="50px">
                             <span><span class="profile_title">{{account.companyname}}</span><br>
                             <span style="color: white;">{{account.email}}</span></span>
                        </li>
                        <li><a routerLink="/profile" routerLinkActive="active"><i class="fa fa-user-o" aria-hidden="true"></i> View Profile</a></li>                      
                        <li *ngIf="account?.role ==='User'"><a routerLink="/user/helpdesk"><i class="fa fa-phone" aria-hidden="true"></i> Help & Support</a></li>
                        <li *ngIf="account?.role ==='Admin'"><a routerLink="/admin/helpDesk"><i class="fa fa-phone" aria-hidden="true"></i> Help & Support</a></li>
                        <li><a (click)="logout()"><i class="fa fa-sign-out" aria-hidden="true"></i> Logout</a></li>
                    </ul>
                </div>
            </div>
                <!-- <router-outlet name="subnav"></router-outlet> -->
            </header>
            <div class="flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <!-- main router outlet -->
                <router-outlet></router-outlet>
            </div>
        </main>
        
    </div>
</div>
<div *ngIf="!account">
    <router-outlet></router-outlet>
</div>
</div>
