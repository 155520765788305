import { AbstractControl, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { AccountService } from '@app/_services';
import { map, switchMap } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
export function duplicateEmailcheckValidator(apiService: AccountService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    const emailId = control.value;
    let debouncetime = 1000;
    return timer(debouncetime).pipe(switchMap(() => {
     return apiService.emailCheck( emailId )
    .pipe(
    map(resp => {
      if(resp['data'] == true){
        return { "duplicateEmailid" : resp['data']}
      }
    })
    )
    }))
  }
}
