import { Injectable } from '@angular/core';
import { Trade } from '../_models/trade';
import { environment } from '@environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
interface PaginatedTrades {
  trades: Trade[];
  total: number;
}
const baseUrl = `${environment.apiUrl}/trades`;
@Injectable({
  providedIn: 'root'
})
export class TradeService {

  constructor(private http: HttpClient) { }

  registerTrade(trade: Trade) {
    return this.http.post(`${baseUrl}/register-new-trade`, trade);
  }

//   getAllTrades(page, size) {
//     return this.http.get<any[]>(`${baseUrl}/all-trades`);
// }

getAllTrades(page: number = 1, size: number = 10, filter: any = {}): Observable<PaginatedTrades> {
  let params = new HttpParams()
    .set('page', page.toString())
    .set('size', size.toString());
  Object.keys(filter).forEach(key => {
    let value = filter[key];
    if (typeof value === 'object' && value !== null) {
      value = JSON.stringify(value); // Convert object to JSON string
    }
    params = params.set(key, value);
  });

  return this.http.get<PaginatedTrades>(`${baseUrl}/all-trades`, { params });
}

  approveTrade(tradeId:any, verifiedby:any,aprrovestatus:string, file:any,purchaseinstruction:any){
    const formData = new FormData();
    formData.append('file', file);
    formData.append('purchaseinstruction', purchaseinstruction);
    formData.append("verifiedby",verifiedby);
    formData.append("aprrovestatus",aprrovestatus);   
    return this.http.put(`${baseUrl}/approve-trade?Id=${tradeId}`, formData);
  }

  cancelTrade(tradeId:any,reason:string,clientStatus:string,adminStatus:string){
    let payload = {
      rejectionreasion: reason,
      clienttradestatus: clientStatus,
      admintradestatus:adminStatus
    }
    return this.http.put(`${baseUrl}/cancel-trade?Id=${tradeId}`, payload);
  }

  appoveTradeByCient(tradeId:any){
    let payload = {
      clienttradestatus: "Processing",
      admintradestatus: "Murabaha form accepted"
  }
    return this.http.put(`${baseUrl}/client-approve-trade?Id=${tradeId}`,payload);
  }

  getTradesByCustomerId(customerId:any){
    return this.http.get<any[]>(`${baseUrl}/get-trade?customerId=${customerId}`);
  }

  getTradeInfo(tradeID:any){
    return this.http.get<any[]>(`${baseUrl}/gettradeinfo?tradeID=${tradeID}`);
  }

}
