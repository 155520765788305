﻿<!-- <div class="p-4">
    <div class="container">
        <h1>Hi {{account.email}}</h1>
    </div>
</div> -->
<div>
    
    <button mat-button class="new-order-btn">
        <a routerLink="/newOrder" style="text-decoration: none">New Order</a>
        <mat-icon class="material-icons-outlined mat-icon">add</mat-icon>
    </button>
    <button mat-button class="new-order-btn">
        <a routerLink="/admin/tradeHistory" style="text-decoration: none">Trade History</a>
        <mat-icon class="material-icons-outlined mat-icon">history</mat-icon>
    </button>
    <!-- <button mat-button class="new-order-btn" (click)="showNewOrderComponent()">New Order<mat-icon class="material-icons-outlined mat-icon">add</mat-icon></button>
    <button mat-button class="new-order-btn">Trade History<mat-icon class="material-icons-outlined mat-icon">history</mat-icon></button> -->
    
</div>
