<div class="newOrderCard">
    <div>
      <div class="dashboard">Dashboard </div>
      <div class="breadcum">Dashboard/ Home / New Order </div>
    </div>
    <div *ngIf=" submitted=== false && !isMurabahaConfirmed && !isSaleConfirmed">
      <div class="table-header">
        <mat-icon class="material-icons-outlined add-icon">add</mat-icon>New
        Order
      </div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row firstRow">
          <div class="form-group col-6">
            <label>Product Type</label>
            <select formControlName="productType" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.productType.errors }">
              <option *ngFor="let prod of prodTypes" [value]="prod.value">
                {{ prod.viewValue }}
              </option>
            </select>
            <div *ngIf="submitted && f.productType.errors" class="invalid-feedback">
                <div *ngIf="f.productType.errors.required">Product Type is required</div>
            </div>
        </div>
      
        <div class="form-group col-6">
          <label>Commodity Type</label>
            <select formControlName="commodityType" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.commodityType.errors }">
              <option *ngFor="let commd of commdityTypes" [value]="commd.value">
                {{ commd.viewValue }}
              </option>
            </select>
        </div>
      </div>
      <!-- <app-svg-icon class="sea" [path]="'assets/svgs/Profile.svg'"></app-svg-icon> -->
      <div class="row remainingRows">
        <div class="form-group col-12">
          <label>Client 1</label>
          <div class="eachField">
            <input type="text" formControlName="clientName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.clientName.errors }" />      
            <svg-icon name="profile" svgClass="bg-red-400 h-20 w-20"></svg-icon>
          </div>      
          <div *ngIf="submitted && f.clientName.errors" class="invalid-feedback">
              <div *ngIf="f.clientName.errors.required">Client 1 is required</div>
          </div>
      </div>  
        <div class="form-group col-12">
          <label>Client 2</label>
          <div class="eachField">
            <input type="text" formControlName="clientName2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.clientName2.errors }" />      
            <svg-icon name="profile" svgClass="bg-red-400 h-20 w-20"></svg-icon>
          </div>      
          <div *ngIf="submitted && f.clientName2.errors" class="invalid-feedback">
              <div *ngIf="f.clientName2.errors.required">Client 2 is required</div>
          </div>
      </div>  
      </div>
      <div class="row remainingRows">
        <div class="form-group col-6">
          <label>Order date</label>
          <!-- <div class="eachField"> -->
            <mat-form-field >
                <input matInput [matDatepicker]="orderDate" formControlName="orderDate" />
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="orderDate"
                  class="datepicker-toggle"
                ></mat-datepicker-toggle>
                <mat-datepicker #orderDate></mat-datepicker>
              </mat-form-field>
          <!-- </div> -->
         
        </div>
        <div class="form-group col-6">
          <label>Value date</label>
          <!-- <div class="eachField"> -->
            <mat-form-field>
                <input matInput [matDatepicker]="valueDate"  formControlName="valueDate"/>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="valueDate"
                  class="datepicker-toggle"
                ></mat-datepicker-toggle>
                <mat-datepicker #valueDate></mat-datepicker>
              </mat-form-field>
          <!-- </div>      -->
        </div>
      </div>
      <div class="row remainingRows">
        <div class="form-group col-6">
          <label>Currency</label>
          <div class="eachField">         
            <mat-select formControlName="currency"  placeholder="Select currency">  
                <mat-option value="MYR">MYR</mat-option>
                <mat-option value="USD">USD</mat-option>
                <mat-option value="GBP">GBP</mat-option>
                <mat-option value="EUR">EUR</mat-option>	
                <mat-option value="JPY">JPY</mat-option>
                <mat-option value="HKD">HKD</mat-option>	
                <mat-option value="CNY">CNY</mat-option>
                <mat-option value="AUD">AUD</mat-option>	
                <mat-option value="NZD">NZD</mat-option>
                <mat-option value="CAD">CAD</mat-option>	
                <mat-option value="SGD">SGD</mat-option>
                <mat-option value="THB">THB</mat-option>
                <mat-option value="VND">VND</mat-option>
                <mat-option value="PHP">PHP</mat-option>
                <mat-option value="KRW">KRW</mat-option>
                <mat-option value="AED">AED</mat-option>
                <mat-option value="IDR">IDR</mat-option>
                <mat-option value="INR">INR</mat-option>
                <mat-option value="SDR">SDR</mat-option>
                <mat-option value="EGP">EGP</mat-option>
                <mat-option value="BND">BND</mat-option>
                <mat-option value="SAR">SAR</mat-option>
                <mat-option value="KHR">KHR</mat-option>
                <mat-option value="MMK">MMK</mat-option>
                <mat-option value="PKR">PKR</mat-option>
                <mat-option value="NPR">NPR</mat-option>
                <mat-option value="CHF">CHF</mat-option>
                <mat-option value="TWD">TWD</mat-option> 
              </mat-select>
        </div>
        </div>   
      </div>
      <div class="row remainingRows">
        <div class="form-group col-6">
          <label>Nominal Amount</label>
          <div class="eachField">
            <input type="text" formControlName="nominalAmount" separator class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nominalAmount.errors }" />
            <mat-icon class="material-icons-outlined">paid</mat-icon>
            <!-- <svg-icon name="curre" svgClass="bg-red-400 h-20 w-20"></svg-icon> -->
          </div>  
         
          <div *ngIf="submitted && f.nominalAmount.errors" class="invalid-feedback">
              <div *ngIf="f.nominalAmount.errors.required">Nominal Amount is required</div>
          </div>
      </div>
      <div class="form-group col-6">
        <label>Sale Amount</label>
        <div class="eachField">
          <input type="text" formControlName="saleAmount" separator class="form-control" [ngClass]="{ 'is-invalid': submitted && f.saleAmount.errors }" />
          <mat-icon class="material-icons-outlined">paid</mat-icon>   
        </div>      
        <div *ngIf="submitted && f.saleAmount.errors" class="invalid-feedback">
            <div *ngIf="f.saleAmount.errors.required">Sale Amount is required</div>
        </div>
      </div>
      </div>
      <div class="row remainingRows">
        <div class="form-group col-6">
          <label>Order Type</label>
            <select formControlName="orderType" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.orderType.errors }">
              <option *ngFor="let order of orderTypes" [value]="order.value">
                {{ order.viewValue }}
              </option>
            </select>
        </div> 
        <div class="form-group col-6">
          <label>Order Tenor</label>
            <select formControlName="orderTenor" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.orderTenor.errors }">
              <option *ngFor="let order of orderTenor" [value]="order.value">
                {{ order.viewValue }}
              </option>
            </select>
        </div>    
      </div>
      <div class="row remainingRows">
        <div class="form-group col-6">
          <label>Order Tenor Other Unit</label>
            <select formControlName="tenorOtherUnit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.tenorOtherUnit.errors }">
              <option *ngFor="let order of OtherTenorUnit" [value]="order.value">
                {{ order.viewValue }}
              </option>
            </select>
        </div> 
        <div class="form-group col-6">
          <label>Tenor Other</label>
            <select formControlName="tenorOther" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.tenorOther.errors }">
              <option *ngFor="let order of OtherTenor" [value]="order.value">
                {{ order.viewValue }}
              </option>
            </select>
        </div>    
      </div>
      <div class="form-group footer">
        <button class="btn btn-primary">Submit</button>
        <a routerLink="/" class="btn btn-link">Cancel</a>
      </div>
      
    </form>
    
    </div>
    <div *ngIf=" submitted=== true && !isMurabahaConfirmed && !isSaleConfirmed">
      <div class="table-header">
        <mat-icon class="material-icons-outlined add-icon">campaign</mat-icon>Trade Order Notification
      </div>
      <div>
        <div style="margin:10px">
          <div style="font-size: 24px;
          font-weight: 500;">Trade Order Notification</div>
          <div class="contact-label">(Certificate of Purchase - Certificate 1)</div>
        </div>
        <div style="margin:10px">Confirmation of commodity purchase transaction terms:</div>
        <div class="example-element-diagram">
          <div class="rowDetails">
             <div class="rowLeft">
               <div class="row-trade-order">
                 <div class="cell">
                   <div class="contact-label">Buyer</div>
                   <div class="label-value">SABIQ ADVISORY FZE</div>
                 </div>
                <div class="cell">
                 <div class="contact-label">Owner / As Agent Of</div>
                 <div class="label-value">{{this.form.value.clientName}}</div>
                </div>
                <div class="cell">
                 <div class="contact-label">Client 2</div>
                 <div class="label-value">{{this.form.value.clientName2}}</div>
                </div>
               </div>
  
               <div class="row-trade-order">
                 <div class="cell">
                   <div class="contact-label">Commodity Type</div>
                   <div class="label-value">{{this.form.value.commodityType}}</div>
                 </div>
                <div class="cell">
                 <div class="contact-label">Product Type</div>
                 <div class="label-value">{{this.form.value.productType}}</div>
                </div>
               </div>
  
               <div class="row-trade-order">
                <div class="cell">
                  <div class="contact-label">Currency</div>
                  <div class="label-value">{{this.form.value.currency }}</div>
                </div>
               <div class="cell">
                <div class="contact-label">Nominal Amount</div>
                <div class="label-value">{{this.form.value.nominalAmount | currency:this.form.value.currency}}</div>
               </div>
              </div>
  
              
              <div class="row-trade-order">
                <div class="cell">
                  <div class="contact-label">Order Date</div>
                  <div class="label-value">{{this.form.value.orderDate | date:'dd/MM/YYYY HH:mm:ss'}}</div>
                </div>
               <div class="cell">
                <div class="contact-label">Value Date</div>
                <div class="label-value">{{this.form.value.valueDate  | date:'dd/MM/YYYY'}}</div>
               </div>
              </div>
  
              <div class="row-trade-order">
                <div class="cell">
                  <div class="contact-label">User</div>
                  <div class="label-value">{{account['companyname']}}</div>
                </div>            
              </div>
  
             </div>
       
          </div>
         </div>
        
      </div>
      <div style="display: flex;align-items: center;">
        <div>
          <button class="btn btn-primary" (click)="confirmOrder()">Accept to Proceed</button>     
        </div>
        <!-- <div style="margin-left: 5px;">
          <button mat-button class="cancelBtn" (click)="rejectOrder()">Reject Order</button>
        </div> -->
        <div style="margin-left: 5px;">
          <button mat-button class="cancelBtn" (click)="cancelOrder()">Cancel</button>
        </div>
      </div>
    </div>
  
    <!-- certificate 2 -->
    <!-- <div *ngIf="isMurabahaConfirmed=== true && !showBuySell">
      <div class="table-header">
        <mat-icon class="material-icons-outlined add-icon">campaign</mat-icon>Trade Order Notification
      </div>
      <div>
        <div style="margin:10px">
          <div style="font-size: 24px;
          font-weight: 500;">Trade Order Notification</div>
          <div class="contact-label">(Certificate of Murabaha - Certificate 2)</div>
        </div>
        <div style="margin:10px">Confirmation of Murabaha transaction terms:</div>
        <div class="example-element-diagram">
          <div class="rowDetails">
             <div class="rowLeft">
               <div class="row-trade-order">
                 <div class="cell">
                   <div class="contact-label">Seller</div>
                   <div class="label-value">{{orderInfo?.ownershipName}}</div>
                 </div>
                <div class="cell">
                 <div class="contact-label">Buyer</div>              
                 <div class="label-value">{{orderInfo?.bankName}}</div>
                </div>
               </div>
  
               <div class="row-trade-order">
                 <div class="cell">
                   <div class="contact-label">Commodity Type</div>
                   <div class="label-value">{{orderInfo?.commodityType}}</div>
                 </div>
                <div class="cell">
                 <div class="contact-label">Product Type</div>
                 <div class="label-value">{{orderInfo?.productType}}</div>
                </div>
               </div>
  
               <div class="row-trade-order">
                <div class="cell">
                  <div class="contact-label">Currency</div>
                  <div class="label-value">{{orderInfo?.orderCurrency}}</div>
                </div>
               <div class="cell">
                <div class="contact-label">Murabaha Amount</div>
                <div class="label-value">{{orderInfo?.murabahaAmount |  currency:orderInfo?.orderCurrency}}</div>
               </div>
              </div>
  
              
              <div class="row-trade-order">
                <div class="cell">
                  <div class="contact-label">Order Date</div>
                  <div class="label-value">{{orderInfo?.orderDate  | date:'dd/MM/YYYY'}}</div>
                </div>
               <div class="cell">
                <div class="contact-label">Value Date</div>
                <div class="label-value">{{orderInfo?.valueDate | date:'dd/MM/YYYY'}}</div>
               </div>
              </div>
  
              <div class="row-trade-order">
                <div class="cell">
                  <div class="contact-label">User</div>
                  <div class="label-value">{{account['companyname']}}</div>
                </div>            
              </div>
  
             </div>
       
          </div>
         </div>
        
      </div>
      <div style="display: flex;align-items: center;">
        <div>
          <button class="btn btn-primary" (click)="confirmCertificate2()">Accept to Proceed</button>     
        </div>
        <div style="margin-left: 5px;">
          <button mat-button class="cancelBtn">Cancel</button>
        </div>
      </div>
    </div> -->
  
      <!-- certificate 3 -->
      <!-- <div *ngIf="isSaleConfirmed=== true && !showBuySell">
        <div class="table-header">
          <mat-icon class="material-icons-outlined add-icon">campaign</mat-icon>Trade Order Notification
        </div>
        <div>
          <div style="margin:10px">
            <div style="font-size: 24px;
            font-weight: 500;">Trade Order Notification</div>
            <div class="contact-label">(Certificate of Sale - Certificate 3)</div>
          </div>
          <div style="margin:10px">Confirmation of Commodity Sale Transaction terms:</div>
          <div class="example-element-diagram">
            <div class="rowDetails">
               <div class="rowLeft">
                 <div class="row-trade-order">
                   <div class="cell">
                     <div class="contact-label">Seller</div>
                     <div class="label-value">SABIQ ADVISORY FZE</div>
                   </div>
                  <div class="cell">
                   <div class="contact-label">Buyer</div>              
                   <div class="label-value">To be selected by SAA</div>
                  </div>
                 </div>
  
                 <div class="row-trade-order">
                  <div class="cell">
                    <div class="contact-label">As Agent Of</div>
                    <div class="label-value">SABIQ ADVISORY FZE</div>
                  </div>           
                </div>
    
                 <div class="row-trade-order">
                   <div class="cell">
                     <div class="contact-label">Commodity Type</div>
                     <div class="label-value">{{this.form.value.commodityType}}</div>
                   </div>
                  <div class="cell">
                   <div class="contact-label">Product Type</div>
                   <div class="label-value">{{this.form.value.productType}}</div>
                  </div>
                 </div>
    
                 <div class="row-trade-order">
                  <div class="cell">
                    <div class="contact-label">Currency</div>
                    <div class="label-value">{{this.form.value.currency }}</div>
                  </div>
                 <div class="cell">
                  <div class="contact-label">Nominal Amount</div>
                  <div class="label-value">{{this.form.value.nominalAmount | currency:this.form.value.currency}}</div>
                 </div>
                </div>
    
                
                <div class="row-trade-order">
                  <div class="cell">
                    <div class="contact-label">Order Date</div>
                    <div class="label-value">{{this.form.value.orderDate | date:'dd/MM/YYYY HH:mm:ss'}}</div>
                  </div>
                 <div class="cell">
                  <div class="contact-label">Value Date</div>
                  <div class="label-value">{{this.form.value.valueDate  | date:'dd/MM/YYYY'}}</div>
                 </div>
                </div>
    
                <div class="row-trade-order">
                  <div class="cell">
                    <div class="contact-label">User</div>
                    <div class="label-value">{{account['companyname']}}</div>
                  </div>            
                </div>
    
               </div>
         
            </div>
           </div>
          
        </div>
        <div style="display: flex;align-items: center;">
          <div>
            <button class="btn btn-primary" (click)="confirmCertificate3()">Accept to Proceed</button>     
          </div>
          <div style="margin-left: 5px;">
            <button mat-button class="cancelBtn">Cancel</button>
          </div>
        </div>
      </div> -->
  
      <!-- buy and sell order list -->
      <!-- <div *ngIf="showBuySell">
        buy and sell
      </div> -->
   
  </div>
  
  