import { Component } from '@angular/core';

import { AccountService } from '@app/_services';

@Component({ 
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.scss']
 })
export class HomeComponent {
    //display = false;
    account = this.accountService.accountValue;

    constructor(private accountService: AccountService) { }

    // showNewOrderComponent(){
    //     this.display = true;
    // }
}