import { Injectable } from '@angular/core';
import { Trade } from '../_models/trade';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/orbit`;
@Injectable({
  providedIn: 'root'
})
export class OrbitService {

  constructor(private http: HttpClient) { }

  register(payload:any){
    const formData = new FormData();
    formData.append('bankname', payload.bankname);
    formData.append("address",payload.address);
    formData.append("country",payload.country);   
    formData.append('zipcode', payload.zipcode);
    formData.append("phonenumber",payload.phonenumber);  
    return this.http.post(`${baseUrl}/register`, payload)
  }

  getAllorbitclients() {
    return this.http.get<any[]>(`${baseUrl}/all-orbitclients`);
  }

  getOrbitClientDetails(orbitId:any){
    return this.http.post(`${baseUrl}/get-orbitclients`,{_id :orbitId })
  }
  updateOrbitClientDetails(orbitId:any, data:any){
    return this.http.put(`${baseUrl}/update-orbitclients?id=`+orbitId, data)
  }
  shareLink(data:any){
    return this.http.post(`${baseUrl}/sharelink`, data)
  }


}
