
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AccountService, AlertService } from '@app/_services';
import { MustMatch } from '@app/_helpers';
import { DataService } from 'src/app/_services/data.service';
import { buyOrder, clientTrade } from 'src/app/_models/trade';
import { OrderService } from 'src/app/_services/order.service';
import { MatSnackBarComponent } from 'src/app/shared/components/mat-snack-bar/mat-snack-bar.component';
import { TradeService } from 'src/app/_services/trade.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { APP_DATE_FORMATS, AppDateAdapter } from 'src/app/shared/date.adapter';
import moment from 'moment';
import { BankService } from 'src/app/_services/bank.service';

interface productType {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-re-initiate-order',
  templateUrl: './re-initiate-order.component.html',
  styleUrls: ['./re-initiate-order.component.less'],
  providers: [
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    ]
})
export class ReInitiateOrderComponent implements OnInit,OnDestroy {
  prodTypes: productType[] = [
    {value: 'DEPOSIT', viewValue: 'DEPOSIT'},
    {value: 'DEPOSIT - AGENT', viewValue: 'DEPOSIT - AGENT'},
    {value: 'FINANCING', viewValue: 'FINANCING'},
    {value: 'FINANCING - AGENT', viewValue: 'FINANCING - AGENT'},
    {value: 'DERIVATIVES - PRINCIPAL', viewValue: 'DERIVATIVES - PRINCIPAL'},
    {value: 'DERIVATIVES - AGENT', viewValue: 'DERIVATIVES - AGENT'},
    {value: 'INTER-BANK MONEY MARKET - PRINCIPAL', viewValue: 'INTER-BANK MONEY MARKET - PRINCIPAL'},
    {value: 'INTER-BANK MONEY MARKET - AGENT', viewValue: 'INTER-BANK MONEY MARKET - AGENT'},
    {value: 'SUKUK/ISLAMIC PAPERS - PRINCIPAL', viewValue: 'SUKUK/ISLAMIC PAPERS - PRINCIPAL'},
    {value: 'SUKUK/ISLAMIC PAPERS - AGENT', viewValue: 'SUKUK/ISLAMIC PAPERS - AGENT'}
  ];
  commdityTypes: productType[] = [
    {value: '000-OILS', viewValue: 'Crude oil'},
    {value: '000-COPPERMETALS', viewValue: 'Copper & metals'},
    {value: '000-FOOD ITEMS ', viewValue: 'Food items'},
    {value: '000-PLASTIC', viewValue: 'Plastic resin'},
    {value: '000-COPPER', viewValue: 'Copper'},
  ];
  orderTypes:productType[]=[
    {value: 'Buy & Sell', viewValue: 'Buy & Sell'},
    {value: 'Buy & Hold', viewValue: 'Buy, Hold & Sell'},
    {value: 'Buy Only', viewValue: 'Buy Only'}
  ];
  orderTenor:productType[]=[
    {value: 'O/N', viewValue: 'Over Night'},
    {value: 'OTHERS', viewValue: 'OTHERS'}
  ];
  OtherTenorUnit:productType[]=[
    {value: 'DAY', viewValue: 'DAY'},
    {value: 'WEEK', viewValue: 'WEEK'},
    {value: 'MONTH', viewValue: 'MONTH'},
    {value: 'YEAR', viewValue: 'YEAR'}
  ];
  OtherTenor =[
    {value:1,viewValue: 1, category:['DAY','WEEK','MONTH','YEAR']},
    {value:2,viewValue: 2, category:['DAY','WEEK','MONTH','YEAR']},
    {value:3,viewValue: 3, category:['DAY','WEEK','MONTH','YEAR']},
    {value:4,viewValue: 4, category:['DAY','WEEK','MONTH','YEAR']},
    {value:5,viewValue: 5, category:['DAY','WEEK','MONTH','YEAR']},
    {value:6,viewValue: 6, category:['DAY','WEEK','MONTH']},
    {value:7,viewValue: 7, category:['DAY','WEEK','MONTH']},
    {value:8,viewValue: 8, category:['DAY','WEEK','MONTH']},
    {value:9,viewValue: 9, category:['DAY','WEEK','MONTH']},
    {value:10,viewValue: 10, category:['DAY','WEEK','MONTH']},
    {value:11,viewValue: 11, category:['DAY','WEEK','MONTH']},
    {value:12,viewValue: 12, category:['DAY','WEEK','MONTH']},
    {value:13,viewValue: 13, category:['DAY']},
    {value:14,viewValue: 14, category:['DAY']},
    {value:15,viewValue: 15, category:['DAY']},
    {value:16,viewValue: 16, category:['DAY']},
    {value:17,viewValue: 17, category:['DAY']},
    {value:18,viewValue: 18, category:['DAY']},
    {value:19,viewValue: 19, category:['DAY']},
    {value:20,viewValue: 10, category:['DAY']},
    {value:21,viewValue: 21, category:['DAY']},
    {value:22,viewValue: 22, category:['DAY']},
    {value:23,viewValue: 23, category:['DAY']},
    {value:24,viewValue: 24, category:['DAY']},
    {value:25,viewValue: 25, category:['DAY']},
    {value:26,viewValue: 26, category:['DAY']},
    {value:27,viewValue: 27, category:['DAY']},
    {value:28,viewValue: 28, category:['DAY']},
    {value:29,viewValue: 29, category:['DAY']},
    {value:30,viewValue: 30, category:['DAY']}
   
  ]
  form: UntypedFormGroup;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;
  newOrder:clientTrade;
  accounts: any[];
  account = this.accountService.accountValue;
  isMurabahaConfirmed = false;
  isSaleConfirmed = false;
  showBuySell = false;
  orderInfo:buyOrder;
  isBank =false;
  banks :any[];
  constructor(
      private formBuilder: UntypedFormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private accountService: AccountService,
      private alertService: AlertService,
      private dataService:DataService,
      private orderService:OrderService,
      private snackBar: MatSnackBarComponent,
      private tradeService:TradeService,
      private bankService:BankService
  ) {}

  ngOnInit() {
    this.getAllBanks();
    console.log(this.account);
    this.dataService.getNewUserInfo().subscribe(info => {
      this.newOrder = info['tradeObj'];   
      this.isBank = info['type'] ==='BankTrade' ? true :false;
      console.log(this.newOrder); 
     
    });
    this.dataService.getMurabahaConfirm().subscribe((isConfirmed) =>{
      this.isMurabahaConfirmed = isConfirmed['isConfirmed'];
      console.log(this.isMurabahaConfirmed )
    });
    this.dataService.getSaleConfirm().subscribe((isConfirmed) =>{
      this.isSaleConfirmed = isConfirmed['isConfirmed'];
    });
    this.dataService.getTradeObj().subscribe((tradeObj) =>{
      this.orderInfo = tradeObj['tradeObj'];
      console.log(this.orderInfo )
    });
  
  
    this.accountService.getAll()
    .pipe(first())
    .subscribe(accounts => {
      this.accounts = accounts;
      this.fillForm();
      console.log(this.accounts);
      
    }
      );

      this.id = this.route.snapshot.params['id'];
      this.isAddMode = !this.id;

      this.form = this.formBuilder.group({
        productType: ['', Validators.required],
        commodityType: ['', Validators.required],
        clientName: ['', Validators.required],
        clientName2: ['', Validators.required],
        orderDate: ['', Validators.required],
        valueDate: ['', Validators.required],
        currency: ['', Validators.required],
        nominalAmount: ['', Validators.required],
        saleAmount: ['', Validators.required],
        orderType: ['', Validators.required],
        orderTenor:['',Validators.required],
        tenorOther:['',Validators.required],
        tenorOtherUnit:['',Validators.required],
      });

      if (!this.isAddMode) {
          this.accountService.getById(this.id)
              .pipe(first())
              .subscribe(x => this.form.patchValue(x));
      }

     
  }

  ngOnDestroy(): void {
    this.dataService.setSaleConfirm({
      isConfirmed: false
    });
    this.dataService.setMurabahaConfirm({
      isConfirmed: false
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }


  fillForm(){
    if(this.newOrder){
      let clientName = '';
      if(this.isBank){
        clientName = this.banks?.find((item) => item.id===this.newOrder.customerId).bankname;
      }
      else{
        clientName = this.accounts?.find((item) => item.id===this.newOrder.customerId).companyname;
      }
      console.log(clientName);
      this.form.patchValue({
        nominalAmount: this.commify(Number(this.newOrder.costprice)),
        saleAmount: this.commify(Number(this.newOrder.differsaleprice)),
        currency:this.newOrder.currency,
        orderDate:new Date(this.newOrder.created),
        valueDate:new Date(this.convertDateToMMDDYYYY(this.newOrder.traderequestdate)),
        clientName:this.isBank ? this.banks?.find((item) => item.id===this.newOrder.customerId).bankname : this.accounts?.find((item) => item.id===this.newOrder.customerId).companyname
      });
    } 
  } 

  convertDateToMMDDYYYY(datestring:string){
    let dateArray= datestring.split('/');
    return dateArray[1]+'/'+dateArray[0]+'/'+dateArray[2];
  }

  //function is used to convert date object to dd/MM/YYYY formate
  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
  }

  commify(n) {
    var parts = n.toString().split(".");

    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;

    return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
}

  onSubmit() {
      // stop here if form is invalid
      if (this.form.invalid) {
          return;
      }
      let clientName=''
      if(this.isBank){
        clientName = this.banks?.find((item) => item.id===this.newOrder.customerId).bankname;
      }
      else{
        clientName = this.accounts?.find((item) => item.id===this.newOrder.customerId).companyname;
      }
      this.form.get('nominalAmount').patchValue( parseFloat(this.form.get('nominalAmount')?.value.replace(/,/g, '')).toString());
      this.form.get('saleAmount').patchValue( parseFloat(this.form.get('saleAmount')?.value.replace(/,/g, '')).toString());
      let payload = {
        customerId: this.newOrder.customerId,
        tradeID: this.newOrder.id,
        product_type: this.form.value.productType,
        commodity_type: this.form.value.commodityType,
        ownership: clientName,
        ownership2: this.form.value.clientName2,
        value_date: this.convertDate(this.form.get('valueDate').value),
        order_currency: this.newOrder.currency,
        order_amount:  this.form.get('nominalAmount').value,
        murabaha_amount: this.form.get('saleAmount').value,
        tenor: this.form.value.orderTenor,
        tenor_other:this.form.value.tenorOther,
        tenor_other_unit: this.form.value.tenorOtherUnit,
        order_type: this.form.value.orderType,
        registerby:this.account.id    
    }

    this.orderService.reOrder(payload).subscribe((res) =>{
      console.log('register order submitted');
      console.log(res);
      this.snackBar.openSnackBar(res['message'],'Close','green-snackbar');
      this.submitted = true; 
    },(err) =>{
      this.snackBar.openSnackBar('something went wrong','Close','red-snackbar');
    }) 
  }

  confirmOrder(){
    this.orderService.confirmOrder(this.newOrder.id).subscribe((res) =>{
      this.snackBar.openSnackBar('Order confirmed','Close','green-snackbar');
      console.log('is bank',this.isBank);
      if(this.isBank){
        this.router.navigate(['/admin/bankTrades'], { relativeTo: this.route });
      }
      else{
        this.router.navigate(['/admin/tradeRequest'], { relativeTo: this.route });
      }
      
    },(err) =>{
      this.snackBar.openSnackBar('something went wrong','Close','red-snackbar');
    })  
  }

  confirmCertificate2(){
    this.showBuySell = true;
  }

  rejectOrder(){
    this.tradeService.cancelTrade(this.newOrder.id,'rejected during trade initiate',
    'Trade has been declined','Trade has been declined by admin').subscribe((res) =>{
      this.snackBar.openSnackBar('Trade rejected','Close','red-snackbar');
      if(this.isBank){
        this.router.navigate(['/admin/bankTrades'], { relativeTo: this.route });
      }
      else{
        this.router.navigate(['/admin/tradeRequest'], { relativeTo: this.route });
      }
    })
  }

  cancelOrder(){
    this.submitted = false;
   // this.router.navigate(['/newOrder'], { relativeTo: this.route });
  }

  getAllBanks(){
    this.bankService.getAllBanks().subscribe((banks) =>{
      console.log(banks);
      this.banks = banks;
    })
  }
}
