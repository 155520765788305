import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AccountService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
    constructor(
        private router: Router,
        private accountService: AccountService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const account = this.accountService.accountValue;
        if (account) {
            // check if route is restricted by role
            if (route.data.roles && !route.data.roles.includes(account.role)) {
                // role not authorized so redirect to home page              
                if(account.role === 'Admin'){
                    this.router.navigate(['/']);
                }
                else{
                    if(account.role == 'Orbit'){
                        this.router.navigate(['/orbit']);
                    }else{
                        if(account.role == 'AdminOrbit'){
                            this.router.navigate(['/orbitadmin']);
                        }else{
                            this.router.navigate(['/user']);
                        }
                    }
                    
                }
               
                return false;
            }

            // authorized so return true
            return true;
        }

        // not logged in so redirect to login page with the return url 
        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}