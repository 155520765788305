import { Injectable } from '@angular/core';
import { Trade } from '../_models/trade';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/bank`;
@Injectable({
  providedIn: 'root'
})
export class BankService {

  constructor(private http: HttpClient) { }

  registerBank(payload:any,file:any){
    const formData = new FormData();
    formData.append('bankname', payload.bankname);
    formData.append("address",payload.address);
    formData.append("country",payload.country);   
    formData.append('zipcode', payload.zipcode);
    formData.append("phonenumber",payload.phonenumber);
    formData.append("licencenumber",payload.licencenumber);   
    formData.append("permittedactivities",payload.permittedactivities);  
    formData.append("file",file);  
    return this.http.post(`${baseUrl}/register`,formData)
  }

  getAllBanks() {
    return this.http.get<any[]>(`${baseUrl}/all-bank`);
  }

  getBankDetails(bankId:any){
    return this.http.post(`${baseUrl}/get-bank`,{bankId :bankId })
  }

}
