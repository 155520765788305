import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { AuthGuard } from './_helpers';
import { Role } from './_models';
import { AddNewOderComponent } from './home/add-new-oder/add-new-oder.component';
import { ReInitiateOrderComponent } from './home/re-initiate-order/re-initiate-order.component';
import { NewOrderAbleaceComponent } from './home/new-order-ableace/new-order-ableace.component';

const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const linksharedModule = () => import('./linkshared/linkshared.module').then(x => x.LinksharedModule);
const adminModule = () => import('./admin/admin.module').then(x => x.AdminModule);
const profileModule = () => import('./profile/profile.module').then(x => x.ProfileModule);
const userModule = () => import('./user/user.module').then(x => x.UserModule);
const orbitModule = () => import('./orbit-component/orbit.module').then(x => x.OrbitModule);
const adminOrbitModule = () => import('./adminorbit/adminorbit.module').then(x => x.AdminOrbitModule);

const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] ,data: { roles: [Role.Admin] }},
    { path: 'newOrder', component: AddNewOderComponent, canActivate: [AuthGuard],data: { roles: [Role.Admin, Role.AdminOrbit] } },
    { path: 'reOrder', component: ReInitiateOrderComponent, canActivate: [AuthGuard],data: { roles: [Role.Admin, Role.AdminOrbit] } },
    { path: 'newOrderableace', component: NewOrderAbleaceComponent, canActivate: [AuthGuard],data: { roles: [Role.Admin, Role.AdminOrbit] } },
    { path: 'account', loadChildren: accountModule },
    { path: 'linkshared', loadChildren: linksharedModule },
    { path: 'profile', loadChildren: profileModule, canActivate: [AuthGuard] },
    { path: 'admin', loadChildren: adminModule, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
    { path: 'user', loadChildren: userModule, canActivate: [AuthGuard], data: { roles: [Role.User] } },
    { path: 'orbit', loadChildren: orbitModule, canActivate: [AuthGuard], data: { roles: [Role.Orbit] }},
    { path: 'orbitadmin', loadChildren: adminOrbitModule, canActivate: [AuthGuard], data: { roles: [Role.AdminOrbit] }},

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
