import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn:'root'
})
export class DataService {

  constructor() { }

  private newOrder = new BehaviorSubject<any>({});
  private confirmMurabaha = new BehaviorSubject<boolean>(false);
  private confirmSale = new BehaviorSubject<boolean>(false);
  private tradeId = new BehaviorSubject<any>({});
  private brokerId = new BehaviorSubject<any>({});
  private tradeObj = new BehaviorSubject<any>({});

  setNewUserInfo(newOrder: any) {
    this.newOrder.next(newOrder);
  }

  getNewUserInfo() {
    return this.newOrder.asObservable();
  }

  setMurabahaConfirm(isConfirmed: any) {
    this.confirmMurabaha.next(isConfirmed);
  }

  getMurabahaConfirm() {
    return this.confirmMurabaha.asObservable();
  }

  setSaleConfirm(isConfirmed: any) {
    this.confirmSale.next(isConfirmed);
  }

  getSaleConfirm() {
    return this.confirmSale.asObservable();
  }

  
  setTradeId(tradeId: any) {
    this.tradeId.next(tradeId);
  }

  setBrokerId(brokerId: any) {
    this.brokerId.next(brokerId);
  }

  getTradeId() {
    return this.tradeId.asObservable();
  }

  setTradeObj(tradeObj: any) {
    this.tradeObj.next(tradeObj);
  }

  getTradeObj() {
    return this.tradeObj.asObservable();
  }




}